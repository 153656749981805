import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'


import consulting from '../images/services/new/consulting.jpg'
import virtualization from '../images/services/new/virtualization.jpg'
import cloud from '../images/services/new/cloud.jpg'
import backup from '../images/services/new/backup.jpg'


import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import infrastructure from '../images/services/new/infrastructure.jpg'
import network from '../images/services/new/pragicts-itops-networking.jpg'
import server from '../images/services/new/pragicts-itops-servers.jpg'
import planning from '../images/services/new/pragicts-itops-capacity-plannig.jpg'
import databackup from '../images/services/new/pragicts-itops-backup-and-recovery.jpg'
import patch from '../images/services/new/pragicts-itops-patch-management.jpg'
import support from '../images/services/new/pragicts-itops-it-support.jpg'
import storage from '../images/services/new/pragicts-itops-storage.jpg'
import asset from '../images/services/new/pragicts-itops-it-asset-management.jpg'


const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | eCommerce | Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>ECOMMERCE <span> FEATURES</span></h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Features <span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Features</h3> 
                    <h2>ECOMMERCE <span> FEATURES</span></h2>
                    
                </div>
            
                <div className="custom-inner-holder  mar-bottom " >
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-feature-1-icon"></i>
                                    <h3>MOBILE/TABLET</h3>
                                </div>
                              
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Responsive by default. This means your site will look awesome no matter the device. Mobile, tablet, desktop, or a large screen you will certainly make an impression.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-2-icon"></i>
                                    <h3>UNLIMITED CATEGORIES</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Tier-1 eCommerce implementation for online shopping.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-3-icon"></i>
                                    <h3>UNLIMITED PRODUCTS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>No Matter if you want to sell 1 or 1 000 000 products.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-4-icon"></i>
                                    <h3>DOWNLOADABLE PRODUCTS READY</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Makes it easy to sell digital products. All you need to do is click a button, upload the product, and set the price.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-5-icon"></i>
                                    <h3>PRODUCT REVIEWS & RATINGS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Customer product reviews are among the most important factors that trigger the buy or don’t buy decisions customers make. Comes with a powerful integrated comment, reviews, and rating system.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-6-icon"></i>
                                    <h3>REWARD POINTS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>A reward program that drives results. Increase customer lifetime value and return purchases.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-7-icon"></i>
                                    <h3>FILTERS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Create filters in the same way you create categories. This is a great way for customers to refine and narrow down just about anything in your store. Easy find - easy buy.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-8-icon"></i>
                                    <h3>MULTI-CURRENCY</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Sell in any currency, anywhere in the world. Get automatic rates updates. Set up different taxes. Ecommerce selling made easy.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-9-icon"></i>
                                    <h3>MULTI-LINGUAL</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Available in more than 40 languages.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-10-icon"></i>
                                    <h3>TURN MAIL TO YOUR ADVANTAGE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Offers an easy way for customers to get subscribed to your newsletter. Once they do this you can easily get started with your email marketing campaigns.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-11-icon"></i>
                                    <h3>MULTI-STORE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Manage multiple stores from one admin interface. Set products to appear in different stores. Choose a different theme for each store. Localize store settings. Set per store product prices..</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-12-icon"></i>
                                    <h3>OPTIONS, ATTRIBUTES</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Products come in different options. Some feature sizes, while others colors, length, height. No matter the case offers a solution for adding extra important product variables.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-13-icon"></i>
                                    <h3>AFFILIATES</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Comes with an inbuilt Affiliate system, where affiliates can promote specific products and get paid for this. Set different percentages. Offer different payment options such as cheque, Paypal, and bank transfer..</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-14-icon"></i>
                                    <h3>DISCOUNTS, COUPONS, SPECIALS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Retailers often have to lower their prices to keep up with their competition. Offers discounts, coupons, and specials to cover the most popular ways to get attention and increase sales.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-15-icon"></i>
                                    <h3>BACK-UP AND RESTORE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Case of emergency? Allows you to set up your own back-ups and restorations.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-16-icon"></i>
                                    <h3>ADMINISTRATOR DASHBOARD</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>All the important information is available at a glimpse. Get a full overview of what is important with total orders, sales, customers, people online, sales analytics, and many more widgets</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-17-icon"></i>
                                    <h3>USER MANAGEMENT</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>In order to successfully organize an online store, you will need to cooperate with many people, each performing different roles. Allows you to set advanced user privileges and separate access for user groups and users.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-18-icon"></i>
                                    <h3>EXTENSIONS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Is a module-based system that allows users to easily extend the functionality for their needs. Easy quick and modify: Bestsellers, Category, Featured, Latest, Specials, Bestsellers, Manufacturers, Information, Google analytics, and many more pages.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-19-icon"></i>
                                    <h3>EXTENSION INSTALLER</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Expanding your store functionality is a piece of cake. The Extension Installer allows you to upload modules with a few clicks and no technical knowledge.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-20-icon"></i>
                                    <h3>MARKETPLACE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>The Marketplace features more than 10,000+ modules and themes to jump-start, grow, and expand your business. You can find beautiful themes for just about any business, service integrations, payment providers, conversion modules, and many more.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-21-icon"></i>
                                    <h3>100% TRANSLATABLE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Is 100% translatable. Use in your language, get started selling in your language, or expand your business by selling in another language. It is that easy.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-22-icon"></i>
                                    <h3>SEO</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Don’t waste time & money to get your website higher in Search Engines. We know how important this is so we take care of this for you. You can further enhance SEO with some of our Popular SEO Extensions.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-23-icon"></i>
                                    <h3>MARKETING</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>No need for expensive add ons. Marketing in your dashboard allows you to add and track the marketing campaigns you create..</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-24-icon"></i>
                                    <h3>PRODUCT FEEDS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Connect to Google base and Google sitemap and set how Google lists and views your products.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-25-icon"></i>
                                    <h3>GUEST AND REGISTERED USER CHECKOUT</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>When it comes to the most important step and converting a customer - the checkout, provides both Guest and Registered user checkout. The choice is yours.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-26-icon"></i>
                                    <h3>SOCIAL ACCOUNTS LOGIN</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Login and registration using your online accounts including Google, LinkedIn, Facebook, etc.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>

                
                    


                </div>
               

           

                       
            </div>
            <div className="sec-lines"></div>
        </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
